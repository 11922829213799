<template>
    <div class="privacy">
        <Header></Header>
        <div class="container">
            <div class="privacy__title">
                <div class="text-xl"><p>Privacy Policy</p></div>
            </div>
            <div class="privacy__intro" id="block1" :class="{'anim': blocks?.[0]?.visible && blocks?.[0]?.cnt == 1}">
                <div class="text-l mobile">
                    <p>FALKONED LIMITED <span class="italic">(hereinafter referred to as</span><span class="italic">“we”, “our”, “us” or the “Company”),</span></p>
                </div>
                <div class="text-l desk">
                    <p>FALKONED LIMITED <span class="italic">(hereinafter referred to as</span></p>
                </div>
                <div class="text-l desk">
                    <p><span class="italic">“we”, “our”, “us” or the “Company”),</span></p>
                </div>
                <div class="text-s">
                    <p>operating from the website https://www.falkoned.com/ is a British Virgin Islands company, with registration number 1768122, and registered address at Quijano Chambers, P.O. Box 3159, Road Town, Tortola, British Virgin Islands The Company is committed to providing digital marketing solutions services.</p>
                </div>
            </div>
            <div class="privacy__content d-flex column">
                <div class="block" id="block2" :class="{'anim': blocks?.[1]?.visible && blocks?.[1]?.cnt == 1}">
                    <div class="text-m">
                        <p>1. Introduction</p>
                    </div>
                    <div class="text-s">
                        <p>This Policy provides an overview on how our Company protects the personal data and privacy of individuals who register to use our services (hereinafter referred to as the “Services”).</p>
                    </div>
                    <div class="text-s">
                        <p>The Company is committed to protecting your privacy and handling your data in an open and transparent manner and in accordance with the European Regulation 2016/679 and the relevant data protection legislation which is applicable in the British Virgin Islands. The personal data that we collect and process depends on the product or service requested and agreed in each case.</p>
                    </div>
                    <div class="text-s">
                        <p>Effective as of August 31, 2023, FALKONED LIMITED have updated the Privacy Policy (hereinafter referred to as the “Policy”).</p>
                    </div>
                </div>
                <div class="block" id="block3" :class="{'anim': blocks?.[2]?.visible && blocks?.[2]?.cnt == 1}">
                    <div class="text-m">
                        <p>2. What we need</p>
                    </div>
                    <div class="text-s">
                        <p>Our Personal Data Protection Policy governs the use and storage of your data. FALKONED LIMITED is a Controller of the personal data you provide to us. We only collect basic personal data about you.</p>    
                    </div>
                    <div class="text-s">
                        <p>Subscriber’s personal data (meaning any information about You from which You can be personally identified, such as Your name, telephone number or email address, and which is referred to in this Privacy Notice as "Your Information"), will be processed by FALKONED LIMITED (which include its agents and employees), and (where appropriate) our partners and subcontractors in order to be able to provide the full range of the Services to the Subscriber. The Subscriber’s information is collected when the Subscriber fills in a feedback form on the website https://www.falkoned.com/.</p>
                    </div>
                </div>
                <div class="block" id="block4" :class="{'anim': blocks?.[3]?.visible && blocks?.[3]?.cnt == 1}">
                    <div class="text-m">
                        <p>3. Why we need it</p>
                    </div>
                    <div class="text-s">
                        <p>We need your personal data in order to provide you with the following services: Contacting the person - collection of Authentication data: name, email, phone number for the purposes of contacting the person. Communicating the person - collection of email addresses for the purpose of communication with us.</p>
                    </div>
                    <div class="text-s">
                        <p>Legal basis: Data Subject consent, conclusion and performance of the contract.</p>
                    </div>
                </div>
                <div class="block" id="block5" :class="{'anim': blocks?.[4]?.visible && blocks?.[4]?.cnt == 1}">
                    <div class="text-m">
                        <p>4. Who receives your personal data</p>
                    </div>
                    <div class="text-s">
                        <p>We disclose your personal data to persons authorized by us to process personal data (i.e., entities we contract to carry out various personal data processing operations on our behalf).  Also, if the law obliges us or we have a legal basis to do so, we may disclose your personal data to third-party entities acting as controllers (entities that process data for their own purposes).</p>
                    </div>
                </div>
                <div class="block" id="block6" :class="{'anim': blocks?.[5]?.visible && blocks?.[5]?.cnt == 1}">
                    <div class="text-m">
                        <p>5. Transfer of your personal data to a third country or to an international organization</p>
                    </div>
                    <div class="text-s">
                        <p>We disclose your personal data to persons authorized by us to process personal data (i.e., entities we contract to carry out various personal data processing operations on our behalf).  Also, if the law obliges us or we have a legal basis to do so, we may disclose your personal data to third-party entities acting as controllers (entities that process data for their own purposes).</p>
                    </div>
                </div>
                <div class="block" id="block7" :class="{'anim': blocks?.[6]?.visible && blocks?.[6]?.cnt == 1}">
                    <div class="text-m">
                        <p>6. How long we keep your personal information for</p>
                    </div>
                    <div class="text-s">
                        <p>All Personal Data will be kept in a format that allows the Data Subjects to be identified for no longer than is necessary for the purposes for which the personal data are processed.</p>
                    </div>
                    <div class="text-s">
                        <p>Personal data may be stored for longer periods of time if personal data are processed solely for archiving purposes and for reasons of general interest, scientific or historical research or for statistical purposes or for the defense of any legal rights.</p>
                    </div>
                </div>

                <div class="block" id="block8" :class="{'anim': blocks?.[7]?.visible && blocks?.[7]?.cnt == 1}">
                    <div class="text-m">
                        <p>7. Your data protection rights</p>
                    </div>
                    <div class="text-s">
                        <p>You have the following rights, in terms of your personal data we hold about you:</p>
                    </div>
                    <ul class="text-s">
                        <li><p><p>Receive access to your personal data. This enables you to e.g. receive a copy of the personal data we hold about you and to check that we are lawfully processing it.</p></p></li>
                        <li><p><p>Request correction of the personal data we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected.</p></p></li>
                        <li><p><p>Request erasure of your personal information. This enables you to ask us to erase your personal data [known as the ‘right to be forgotten’] where there is no good reason for us continuing to process it.</p></p></li>
                        <li><p><p>Object to processing of your personal data, where we are relying on a legitimate interest and there is something about your particular situation which makes you want to object to processing on this ground. If you lodge an objection, we will no longer process your personal data unless we can demonstrate compelling legitimate grounds for the processing which override your interests, rights and freedoms.</p></p></li>
                        <li><p><p>Withdrawal of the consent that you gave us with regards to the processing of your personal data at any time. Note that any withdrawal of consent shall not affect the lawfulness of processing based on consent before it was withdrawn or revoked by you.</p></p></li>
                    </ul>
                    <div class="text-s">
                        <p>In the event that you wish to complain about how we handled your personal data, please contact FALKONED LIMITED DPO by the following email address: info@falkoned.com.</p>
                    </div>
                </div>
                <div class="block" id="block9" :class="{'anim': blocks?.[8]?.visible && blocks?.[8]?.cnt == 1}">
                    <div class="text-m">
                        <p>8. Right to lodge a complaint</p>
                    </div>
                    <div class="text-s">
                        <p>If after complaining to the DPO you still feel that your Personal Data has not been handled appropriately, according to the law, you can lodge a complaint with Virgin Islands (British) regarding the Processing of your Personal Data by us or on our behalf:</p>
                    </div>
                    <div class="text-s">
                        <p>Confidentiality and Data Protection  Address: 33 Admin Drive, Wickhams Cay 1, Road Town, Tortola, Virgin Islands (British) Email: gis@gov.vg</p>
                    </div>
                </div>
                <div class="block" id="block10" :class="{'anim': blocks?.[9]?.visible && blocks?.[9]?.cnt == 1}">
                    <div class="text-m">
                        <p>9. Changes to this privacy statement</p>
                    </div>
                    <div class="text-s">
                        <p>We may modify or amend this privacy statement from time to time. We will notify you appropriately when we make changes to this privacy statement and we will amend the revision date at the top of this page. We do however encourage you to review this statement periodically so as to be always informed about how we are processing and protecting your personal information.</p>
                    </div>
                </div>
            </div>
        </div>
        <Footer id="block11" :inview="blocks?.[10]?.visible && blocks?.[10]?.cnt==1"></Footer>
    </div>
</template>

<script setup>
import {ref, onMounted, onBeforeUnmount} from "vue";
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import {isElementVisible} from "@/visibility";

const blocks = ref(Array.from({ length: 11 }, () => ({ id: null, visible: false, cnt: 0 })));

const handleScroll = () => {
  blocks.value.forEach((block) => {
    const element = document.getElementById(block.id);
    if (element) {
      const isVisible = isElementVisible(element, 0);
      if (isVisible && !block.visible) {
        block.visible = true;
        block.cnt++;
      } else if (!isVisible && block.visible) {
        block.visible = false;
      }
    }
  });
};

onMounted(() => {
  blocks.value.forEach((block, index) => {
    block.id = `block${index + 1}`;
  });
  handleScroll()
  window.addEventListener('scroll', handleScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll);
});
</script>

<style lang="scss" scoped>
.privacy {
    position: relative;
    .privacy__intro.anim {
        .text-l {
            overflow: hidden;
            & p {
                transform: translateY(100px);
                -webkit-transform: translateY(100px);
                -moz-transform: translateY(100px);
                -o-transform: translateY(100px);
                animation: flow 1s ease forwards;
            }
        }
        .text-s {
            overflow: hidden;
            & p {
                transform: translateY(120%);
                -webkit-transform: translateY(120%);
                -moz-transform: translateY(120%);
                -o-transform: translateY(120%);
                animation: flow 1s ease forwards;
            }
        }
    }
    .block.anim {
        .text-m {
            overflow: hidden;
            & p {
                transform: translateY(100%);
                -webkit-transform: translateY(100%);
                -moz-transform: translateY(100%);
                -o-transform: translateY(100%);
                animation: flow 1s ease forwards;
            }
        }
        .text-s {
            overflow: hidden;
            & p {
                transform: translateY(120%);
                -webkit-transform: translateY(120%);
                -moz-transform: translateY(120%);
                -o-transform: translateY(120%);
                animation: flow 1.2s ease forwards;
            }
        }
        li {
            overflow: hidden;
            & p {
                transform: translateY(120%);
                -webkit-transform: translateY(120%);
                -moz-transform: translateY(120%);
                -o-transform: translateY(120%);
                animation:flow 1.2s ease forwards;
            }
        }
    }
    &__title {
        padding: 52px 0 49px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
    }
    &__intro {
        padding: 54px 0 70px;
        border-bottom: 1px solid rgba(0,0,0,0.1);
        .mobile {
            display: none;
        }
        & .text-l {
            width: 60%;
        }
        & .text-s {
            width: 59%;
            margin-top: 30px;
        }
    }
    &__content {
        margin: 56px 0 80px;
        gap: 30px;
        & .text-m {
            width: 60%;
            margin-bottom: 24px;
        }
        & .text-s {
            width: 59%;
            & + .text-s {
                margin-top: 8px;
            }
        }
        & ul {
            margin: 24px 0;
            & li {
                margin-left: 25px;
                & + li {
                    margin-top: 8px;
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .text-xl {
        font-size: 40px !important;
        line-height: 56px !important;
    }
    .privacy {
        &__title {
            padding: 20px 0 22px;
        }
        &__intro {
            padding: 34px 0 40px;
            .mobile {
                display: block;
                line-height: 44px !important;
            }
            .desk {
                display: none;
            }
            .text-s {
                margin-top: 12px;
            }
            .text-l, .text-s {
                width: 100%;
            }
        }
        &__content {
            margin: 34px 0 64px;
            gap: 34px;
            .text-m {
                margin-bottom: 16px;
            }
            .text-m, .text-s {
                width: 100%;
            }
        }
    }
}
</style>